@font-face {
  font-family: 'Axis';
  font-style: normal;
  font-weight: normal;
  src: local('Axis'), url('/fonts/axis.woff') format('woff');
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('/fonts/NotoKufiArabic.woff2') format('woff2'),
  url('/fonts/NotoKufiArabic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('/fonts/NotoKufiArabic-Bold.woff2') format('woff2'),
  url('/fonts/NotoKufiArabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: Arial, sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  background-size: cover;
  background-position: center;
}

header {
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
}

header img {
  width: 100%;
}

footer {
  width: 100%;
  position: absolute;
  bottom: -4px;
  background-repeat: no-repeat;
  height: 100px;
}

.button-container {
  text-align: center;
}

.button-container button {
  padding: 0 40px;
}

.app-role {
  margin: 60px 30px 60px 70px;
}

.app-role-title {
  font-size: 50px;
  line-height: 62px;
}

.back-button {
  margin-left: 20px;
  display: block;
  color: #163078;
  font-weight: bold;
  padding-left: 20px;
  background: none;
}
.back-button .icon {
  display: inline-block;
  width: 20px;
  height: 26px;
  background: url(/img/back-icon.png) no-repeat;
  vertical-align: middle;
  margin-top: 3px;
}

.checkin-btn {
  background: url("/img/checkin.png") no-repeat;
  width: 101px;
  height: 101px;
  margin-top: 100px;
}

.checkout-btn {
  color: white;
  background: none;
  font-size: 30px;
  margin-top: 200px;
}

.checkout-btn span {
  display: block;
  background: url("/img/checkout-icon.png") no-repeat;
  width: 43px;
  height: 30px;
  margin-left: auto;
}

.checkout {
  /*margin: 152px auto 0 auto;*/
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-repeat: no-repeat;
  background-position: top center;
  width: calc(100% - 30px);
  margin: 0 20px 20px;
  background-size: 100%;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}
.form .next, .form .submit {
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  font-size: 30px;
  display: inline-block;
}
.form input, textarea, select {
  height: 60px !important;
}
.scan {
  padding: 160px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 93vh;
  background-repeat: no-repeat;
  background-position: top center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  margin: 0 20px 20px;
}

.scan img {
  width: 200px;
  margin-bottom: 30px;
}

.scan p {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 46px;
}

.scan p.welcome-msg {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 20px;
}

.first-copyright {
  position: fixed;
  left: 6px;
  top: 30%;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-transform: uppercase;
  font-size: 12px;
}

.second-copyright {
  position: fixed;
  right: 6px;
  top: 20%;
  height: 100%;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-transform: uppercase;
  font-size: 12px;
}

.message {
  margin: 270px auto 0 auto;
  color: red;
}

.appointment .submit {
  color: white;
  width: 280px;
  padding-top: 44px;
  padding-bottom: 20px;
}
.appointment form{
  margin: 0 auto;
}
.appointment {
  position: relative;
  font-size: 22px;
  padding: 100px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 93vh;
  background-repeat: no-repeat;
  background-position: top center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  margin: 0 20px 20px;
}

.success {
  position: relative;
  font-size: 22px;
  padding: 160px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 93vh;
  background-repeat: no-repeat;
  background-position: top center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  margin: 0 20px 20px;
}

.success img {
  width: 200px;
}

.success p, .success h2 {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 46px;
}

.error {
  display: block;
  color: red;
  font-size: 12px;
  width: 470px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.inputs {
  position: relative;
}

input, select, textarea {
  font-size: 20px !important;
}

.closeIcon {
  height: 16px;
  width: 16px;
  right: 17px;
  position: absolute;
  top: 18px;
}

.closeIcon:hover {
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 20px !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 20px !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 20px !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 20px !important;
}

::placeholder {
  font-size: 20px !important;
}

.suggestedList {
  background-color: white;
  color: black;
  list-style: none;
  width: 90%;
  margin-left: 20px;
  position: absolute;
  z-index: 10;
}

.suggestedList li {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.suggestedList li:nth-child(odd) {
  background-color: #fff;
}

.suggestedList li:nth-child(even) {
  background-color: #eee;
}

.suggestedList li:hover {
  cursor: pointer;
  background-color: #6ff4c5;
}

.try-my-luck {
  margin: 45px auto;
  width: 100%;
  text-align: center;
}

.try-my-luck .gifts {
  text-align: center;
  width: 100%;
}

.try-my-luck .gifts .gift-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.gifts-border {
  position: absolute;
  width: 100%;
  bottom: 82px;
  display: block;
  border-bottom: 7px solid #532001;
  z-index: 1;
}

.try-my-luck .gifts .gift-wrapper.congrats .gift {
  background: none;
}

.try-my-luck .gifts .gift-wrapper .gift {
  height: 320px;
  margin: 0 60px;
  background: url(/img/gift-bg.png) no-repeat;
  background-size: 100%;
  z-index: 2;
}

.try-my-luck .gifts .gift-wrapper .gift:first-of-type {
  margin-left: 0;
}

.try-my-luck .gifts .gift-wrapper .gift:last-of-type {
  margin-right: 0;
}

.try-my-luck .gifts .gift-wrapper.congrats .gift img {
  width: auto;
}

.try-my-luck .gifts .gift-wrapper .gift img {
  max-height: 200px;
  max-width: 200px;
  width: 70%;
  transform: translateY(62%);
}

.try-my-luck .gifts .gift-wrapper .gift.has-form img {
  transform: none;
  margin: 20px 0px;
}

.try-my-luck .gifts .gift-wrapper .or {
  text-transform: uppercase;
  font-size: 40px;
  color: #fff;
  padding-top: 120px;
}

.gift.has-form {
  margin-top: 50px;
}

.gift form {
  margin-top: 20px;
}

.single-gift {
  margin-top: 108px;
}

.close-button {
  margin-top: 25px;
}

.gain-success-msg {
  font-size: 30px;
  color: white;
  text-align: center;
  padding-top: 20px;
}

.congrats {
  width: 800px;
  height: 430px;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.titles {
  padding-top: 190px;
}

.legal-notices-button-container {
  text-align: center;
  margin: 10px 0;
}

.legal-notices-button {
  background: none;
  border: none;
  color: black;
  text-decoration: underline;
}

.legal-notices-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: white;
  overflow: auto;
  padding: 20px;
}

.legal-notices-content {
  color: black;
  margin-top: 20px;
}

.notices-close {
  position: fixed;
  top: 20px;
  right: 30px;
  width: 50px;
  height: 50px;
  font-size: 30px;
  background-color: black;
  color: white;
  z-index: 99999;
}
.appointment-btn {
  width: 232px;
  height: 325px;
  background-color: transparent;
  margin-left: -60px;
  position: absolute;
  top: 50%;
}
